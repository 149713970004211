import * as React from "react"
import FullApp from "../../FullApp";
import Layout from "../../templates/layout";
import PaymentConfirmation from "../../components/pages/paymentconfirmation";

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
        <PaymentConfirmation router={props} />
    </Layout>
  </FullApp>
);