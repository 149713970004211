import * as React from "react";
import {
  Button, Divider, Grid, Header,
} from "semantic-ui-react";
import {navigateTo} from "../../helpers/router";
import queryString from "query-string";
import "../../css/paymentconfirmation.css"

interface Props {
  router: any
}

interface State {
  error: boolean
}
export default class PaymentConfirmation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: false
    }
    ;
  }

  componentDidMount() {
    let params = this.props.router.location.search;
    if (params) {
      params = queryString.parse(params);
      if (params.error) {
        this.setState({error: true})
      }
    }
  }

  displayContent() {
    if (this.state.error) {
      return (
        <div className="container negative">
          <Grid textAlign="center">
            <Grid.Row>
              <Grid.Column>
                <Header as='h1' style={{color: "#FFFFFF"}}>Płatność została odrzucona</Header>
                <p style={{color: "#FFFFFF"}}>Coś poszło nie tak. Skontaktuj się z nami: <a className="no-mailgo" href="mailto:sklep@pure4me.pl">sklep@pure4me.pl</a></p>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden/>
            <Grid.Row>
              <Button id="btn-back" onClick={() => navigateTo("/")}>Powrót</Button>
            </Grid.Row>
          </Grid>
        </div>
      )
    } else {
      return (
        <div className="container positive">
          <Grid textAlign="center">
            <Grid.Row>
              <Grid.Column>
                <Header as='h1' style={{color: "#FFFFFF"}}>Twoja płatność została potwierdzona przez PayU. Dziękujemy!</Header>
                <p style={{color: "#FFFFFF"}}>Płatność została potwierdzona.</p>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden/>
            <Grid.Row>
              <Button id="btn-back" onClick={() => navigateTo("/")}>Powrót</Button>
            </Grid.Row>
          </Grid>
        </div>
      )
    }
  }

  render() {
    return (
      <div style={{height: "calc(100vh - 4px)", display: "flex", justifyContent: "center", alignItems: "center"}}>
        {this.displayContent()}
      </div>
    );
  }
}